var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MissionCard", { staticClass: "mobile-result" }, [
    _c(
      "div",
      { staticClass: "mobile-result__team" },
      [
        _c("v-icon", { staticClass: "mr-2", attrs: { dark: "" } }, [
          _vm._v(_vm._s(_vm.team.icon)),
        ]),
        _c("span", [_vm._v(" " + _vm._s(_vm.team.name) + " ")]),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "mobile-result__place",
        class: { "mobile-result__place--success": _vm.team.top },
      },
      [_vm._v(" " + _vm._s(_vm.teamPosition) + " ")]
    ),
    _c("div", { staticClass: "mobile-result__total" }, [
      _vm._v(" " + _vm._s(_vm.team.totalScore)),
      _c("span", { staticClass: "ml-1" }, [_vm._v("PTS")]),
    ]),
    _c("div", { staticClass: "mobile-result__score" }, [
      _vm._v(" " + _vm._s(_vm.team.score > 0 ? "+" + _vm.team.score : 0)),
      _c("span", { staticClass: "ml-1" }, [_vm._v("PTS")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }