























import Vue from "vue"
import MissionCard from "@/components/GroupTeams/Common/Games/GameCardParts/MissionCard.vue"
import useResults from "@/use/useResults"
import useStore from "@/use/useStore"
import { computed } from "@vue/composition-api"

export default Vue.extend({
  name: "MobileResult",
  components: { MissionCard },
  setup() {
    const { store } = useStore()
    const mission = computed(() => store.getters.getCurrentMission)
    const { results } = useResults(mission)
    const viewer = computed(() => store.getters["auth/user"])

    const team = computed(() => {
      const teams = results.value

      return teams.reduce((acc, team, index) => {
        if (team.id === viewer.value.teamID) {
          acc = team
          acc.position = index + 1
          acc.top = teams[0].totalScore === team.totalScore
        }
        return acc
      }, {})
    })

    const teamPosition = computed(() => {
      const position = team.value.position

      switch (position) {
        case 1:
          return `${position}ST PLACE`
        case 2:
          return `${position}ND PLACE`
        case 3:
          return `${position}RD PLACE`
        default:
          return `${position}TH PLACE`
      }
    })
    return { team, teamPosition }
  }
})
