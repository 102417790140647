import { computed, Ref } from "@vue/composition-api"

import Team from "@shared/Team"

import useStore from "./useStore"

export default function useResults(mission) {
  const { store } = useStore()
  const plays = computed(() =>
    store.getters.missionPlaysArray.map(obj => ({
      ...obj,
      score: parseInt(obj.score || 0)
    }))
  )

  const teams = computed(() =>
    Team.normalize(store.getters.chats).filter(
      team => !Team.isSpecialPurpose(team) && team.show
    )
  )

  const results = computed(() =>
    teams.value
      .map(team => {
        return {
          ...team,
          totalScore: parseInt(team.totalScore || 0),
          score: plays.value
            .filter(play => play.teamID === team.id)
            .reduce((a, b) => a + b.score, 0)
        }
      })
      .sort(sortBy())
  )

  function sortBy() {
    if (mission.value?.showMissionResultsOnly)
      return (a, b) => {
        if (a.score > b.score) return -1
        if (a.score < b.score) return 1
      }

    return (a, b) => {
      if (a.totalScore > b.totalScore) return -1
      if (a.totalScore < b.totalScore) return 1

      if (a.name > b.name) return 1
      if (a.name < b.name) return -1
    }
  }

  return { results }
}
